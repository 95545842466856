<script setup>
import text from "~/configs/texts";

const props = defineProps({
  noButton: Boolean,
  narrow: Boolean
})

const refHeaders = ref();
const refIndicators = ref();

const featuresMatrix = [
  [1, 0, 0, 0],
  [1, 1, 0, 0],
  [1, 0, 1, 2],
  [1, 1, 0, 1],
  [1, 1, 1, 1],
  [1, 1, 1, 1],
  [1, 0, 1, 0],
  [1, 1, 1, 2],
  [1, 1, 0, 1]
];

const touch = ref(false);
const startTouch = ref(0);
const scrollLeft = ref(0);
const indicatorsScrollLeft = ref(0);

const startDrag = (e) => {
  const pageX = e.touches ? e.touches[0].pageX : e.pageX;
  touch.value = true;
  startTouch.value = pageX;
  scrollLeft.value = parseInt(refHeaders.value.firstElementChild.style.marginLeft || 0);
  indicatorsScrollLeft.value = parseInt(refIndicators.value[0].firstElementChild.style.marginLeft || 0);
}
const moveDrag = (e) => {
  if (!touch.value) return;
  const indicatorWidth = refIndicators.value[0].firstElementChild.getBoundingClientRect().width;
  const headerWidth = refHeaders.value.firstElementChild.getBoundingClientRect().width;
  const pageX = e.touches ? e.touches[0].pageX : e.pageX;
  const offset = Math.round(startTouch.value - pageX);
  const indOffset = Math.round((startTouch.value - pageX) * (indicatorWidth / headerWidth));
  refHeaders.value.firstElementChild.style.marginLeft = Math.round(scrollLeft.value - offset) + "px";

  refIndicators.value.forEach(indicator => {
    indicator.firstElementChild.style.marginLeft = Math.round(indicatorsScrollLeft.value - indOffset) + "px";
  });

}

const endDrag = (e) => {
  touch.value = false;
  nextTick(() => {
    let visibleSlide = Math.round(-parseInt(refHeaders.value.firstElementChild.style.marginLeft) / refHeaders.value.firstElementChild.getBoundingClientRect().width) + 1;
    if (visibleSlide < 1) visibleSlide = 1;
    if (visibleSlide > 4) visibleSlide = 4;
    scrollToSlide(visibleSlide);
  })
}

const currentSlide = ref(1)
const scrollToSlide = (slide) => {
  currentSlide.value = slide;
  refHeaders.value.firstElementChild.style.marginLeft = -((slide - 1) * refHeaders.value.firstElementChild.getBoundingClientRect().width) + "px";
  const indicatorWidth = refIndicators.value[0].firstElementChild.getBoundingClientRect().width;
  refIndicators.value.forEach(indicator => {
    indicator.firstElementChild.style.marginLeft = -((slide - 1) * indicatorWidth) + "px";
  });
}
const request = useRequestURL();
const domain = request.href;
const isCarkulacja = computed(() => {
  return domain.includes('carkulacja');
});

const modifiedTypesIfCarkulacja = ref([...text.leasingFeatures.types]);
watchEffect(() => {
  if (isCarkulacja.value) {
    modifiedTypesIfCarkulacja.value = text.leasingFeatures.types.map(type => {
      if (type === 'Leasing VEHIS') {
        return 'Nasz leasing';
      }
      return type;
    });
  } else {
    modifiedTypesIfCarkulacja.value = [...text.leasingFeatures.types];
  }
});

</script>

<template>
  <div class="w-full bg-white">
    <div class="w-fit mx-auto border-t border-b border-transparent lg:border-0"
         :class="{'!border-0': currentSlide === 1}"
         @touchstart.passive="startDrag"
         @touchmove.passive="moveDrag"
         @touchend.passive="endDrag"
    >
      <div class="flex sticky top-[96px] pt-2 z-20 bg-white"
           :class="[narrow ? 'lg:relative lg:top-0' : 'md:relative md:top-0']">
        <div class="w-[274px] shrink-0 hidden"
             :class="[narrow ? 'xl:block' : 'lg:block']"
        ></div>
        <div ref="refHeaders"
             class="w-[274px] md:w-[189px] grow flex border border-b-0 rounded-tl-lg rounded-tr-lg overflow-hidden transition-colors shadow-box-white md:shadow-none"
             :class="[narrow ? 'xl:w-[162px]' : 'xl:w-[189px] lg:w-[162px]',
                 {'border-2 lg:border !border-b-0 border-b-gray-400 border-claret lg:border-gray-400': currentSlide === 1},
                 {'border border-gray-400': currentSlide !== 1},
                 ]"
        >
          <div v-for="(type, typeIndex) in modifiedTypesIfCarkulacja"
               class="h-[56px] shrink-0 justify-center items-center font-light flex lg:block w-full  lg:!mx-0"
               :class="[
                 narrow ? 'xl:w-[162px]' : 'xl:w-[189px] lg:w-[162px]',
                 {'lg:border-2 lg:border-claret lg:rounded-tl-lg lg:rounded-tr-lg lg:border-b-0': !narrow && typeIndex === 0},
                 {'xl:border-2 xl:border-claret xl:rounded-tl-lg xl:rounded-tr-lg xl:border-b-0': narrow && typeIndex === 0},
                 {'transition-[margin-left] duration-500':!touch}]"
          >
            <div class="flex items-center justify-center text-center h-[56px]"
                 :class="[narrow ? 'xl:break-into-lines' : 'lg:break-into-lines xl:break-into-lines-none']">{{ type }}
            </div>
          </div>
        </div>
      </div>
      <div class="border w-full rounded-lg rounded-tr-none rounded-tl-none "
           :class="[
             narrow ? 'xl:rounded-tl-lg' : 'lg:rounded-tl-lg',
               {'border-2 lg:border !border-t border-t-gray-400 border-claret lg:border-gray-400': !narrow && currentSlide === 1},
               {'border-2 xl:border !border-t border-t-gray-400 border-claret xl:border-gray-400': narrow && currentSlide === 1},
               {'border border-gray-400': currentSlide !== 1}
           ]"
      >
        <div v-for="(label, index) in text.leasingFeatures.labels" class="flex even:bg-gray-100">
          <div class="w-full" :class="[narrow ? 'xl:w-[274px]' : 'lg:w-[274px]']">
            <div class="p-4 font-extralight">{{ label }}</div>
          </div>
          <div ref="refIndicators"
               class="flex overflow-x-hidden w-16 max-w-16 "
               :class="[narrow ? 'xl:max-w-none xl:w-auto' : 'lg:max-w-none lg:w-auto']"
          >
            <div class="h-full justify-center items-center shrink-0 flex px-4 "
                 :class="[
                   narrow ? 'xl:w-[162px] xl:px-0 xl:!mx-0' : 'xl:w-[189px] lg:w-[162px] lg:px-0 lg:!mx-0',
                    {'transition-[margin-left] duration-500':!touch,
                    'lg:border-l-2 lg:border-r-2 lg:border-claret': !narrow && featureIndex === 0,
                    'xl:border-l-2 xl:border-r-2 xl:border-claret': narrow && featureIndex === 0,
                    'lg:border-b-2 lg:rounded-bl-lg lg:rounded-br-lg': !narrow && featureIndex === 0 && index === text.leasingFeatures.labels.length - 1,
                    'xl:border-b-2 xl:rounded-bl-lg xl:rounded-br-lg': narrow && featureIndex === 0 && index === text.leasingFeatures.labels.length - 1}
                    ]"
                 v-for="(feature, featureIndex) in featuresMatrix[index]">
              <IconCheckGreen v-if="feature === 1" />
              <IconMinusGrey v-if="feature === 0" />
              <div v-if="feature === 2" class="font-extralight">n/d</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-6" :class="[narrow ? 'xl:hidden' : 'lg:hidden']">
      <SlideNavigation :items=4 @changed="(slide) => scrollToSlide(slide)" not-responsive />
    </div>
    <div class="pb-6 flex justify-center" :class="[narrow ? 'xl:pt-6' : 'lg:pt-6']" v-if="!noButton">
      <ButtonRounded color="transparent" :href="{name:'static-offer'}" fit>Dowiedz się więcej</ButtonRounded>
    </div>
  </div>
</template>
